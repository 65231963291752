import * as React from "react"
import { Link } from "gatsby"


// markup
const NotFoundPage = () => {
  return (
    <>
      <div className="mx-auto w-1/4 bg-white rounded-xl">
        404 Not Found
      </div>
    </>
  )
}

export default NotFoundPage
